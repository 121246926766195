<template>
    <div class="c-widget--audio c-audio o-container o-container--full o-container--no-pad">
        <div class="o-container">
            <h2 class="c-audio__title">{{ data.title }} <span class="c-audio__subtitle">{{ data.second_title }}</span></h2>
            <div class="c-audio__img" :style="{ backgroundImage: `linear-gradient(180deg, rgba(229, 242, 251, 0) 60.42%, #E5F2FB 95.31%), url(${data.image.sizes.full.src[0]})` }"></div>
            <vue-plyr class="c-player c-player--audio c-audio__player" ref="audio">
                <audio :src="data.audio.url">
                    <source :src="data.audio.url" :type="data.audio.mime_type">
                </audio>
            </vue-plyr>
            <div v-if="data.intro" class="c-audio__intro-text">{{ data.intro }}</div>
            <div v-if="data.quote" class="c-quote c-quote--compact c-audio__intro-quote">
                <div class="c-quote__inner">
                    <blockquote class="c-quote__body">
                        <p class="c-quote__text">{{ data.quote }}</p>
                    </blockquote>
                </div>
            </div>
            <div class="c-audio__text" :class="{ 'c-audio__text--expanded': expandText }" v-if="data.text" v-html="data.text"></div>
            <button v-if="!expandText" class="c-btn c-btn--load-more c-audio__text-trigger" @click="expandText = true">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.669 1l-6.5 6-6.5-6"/></svg>
            </button>
            <button v-if="expandText" class="c-btn c-btn--load-more c-audio__text-trigger c-audio__text-trigger--expanded" @click="expandText = false">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" transform="rotate(180)" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.669 1l-6.5 6-6.5-6"/></svg>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Audio',

        data () {
            return {
                expandText: false
            }
        },

        mounted() {
            const audioEl = this.$refs.audio.$el;
            const audioPlyr = audioEl.querySelector('.plyr');
            const audioProgressContainer = audioEl.querySelector('.plyr__progress__container');

            audioPlyr.insertBefore(audioProgressContainer, null)
        },

        props: {
            data: Object
        },
    }
</script>

<style lang="scss" src="@/styles/components/_player.scss"></style>
<style lang="scss" src="@/styles/components/_audio.scss"></style>